import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
declare var $: any;

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;

    constructor(private translate: TranslateService, private router: Router) {
        this.translate.stream('error').subscribe((res : string) => { this.errorTranslation = res; });
        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings - footer
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'ServicePay Frontend',
            year: ((new Date()).getFullYear()),
            version: environment.version
        };

        // Layout Settings - basic settings for layout & Co.
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    sessionExpired() {
      localStorage.clear();
    }

    manageErrorMsg(outcome) {
      let message = "";
      switch (outcome.code) {
        case '0000':
          message = this.errorTranslation.error_0000;
          break;
        case '0001':
          message = this.errorTranslation.error_0001;
          break;
        case '0002':
          message = this.errorTranslation.error_0002;
          break;
        case '0003':
          message = this.errorTranslation.error_0003;
          break;
        case '0004':
          message = this.errorTranslation.error_0004;
          break;
        case '0005':
          message = this.errorTranslation.error_0005;
          break;
        case '0006':
          message = this.errorTranslation.error_0006;
          break;
        case '0007':
          message = this.errorTranslation.error_0007;
          break;
        case '0008':
          message = this.errorTranslation.error_0008;
          break;
        case '0009':
          message = this.errorTranslation.error_0009;
          break;
        case '0010':
          message = this.errorTranslation.error_0010;
          break;
        case '0011':
          message = this.errorTranslation.error_0011;
          break;
        case '0012':
          message = this.errorTranslation.error_0012;
          break;
        case '0013':
          message = this.errorTranslation.error_0013;
          break;
        case '0014':
          message = this.errorTranslation.error_0014;
          break;
        case '0015':
          message = this.errorTranslation.error_0015;
          break;
        case '0016':
          message = this.errorTranslation.error_0016;
          break;
        case '0017':
          message = this.errorTranslation.error_0017;
          break;
        case '0018':
          message = this.errorTranslation.error_0018;
          break;
        case '0019':
          message = this.errorTranslation.error_0019;
          break;
        case '0026':
          message = this.errorTranslation.error_0019;
          break;
        default:
          message = this.errorTranslation.generic_error;
        break;
      }
      return message;
    }

    getLocalStorage( key ) {
      return localStorage.getItem( key )
    }

    setLocalStorage( key , value ) {
      localStorage.setItem( key , value )
    }

}
